<template>
    <v-menu min-width="250" max-width="250" left bottom offset-y transition="scale-transition" origin="center center" rounded="lg" :close-on-content-click="false" v-model="cart_menu">
        <template v-slot:activator="{ on }">
            <v-badge
                class="mr-3"
                :content="cart_products ? cart_products.length : 0"
                :value="cart_products ? cart_products.length > 0 : false"
                color="error"
                overlap
                offset-y="15"
                offset-x="10"
            >
                <v-btn icon v-on="on" small>
                    <v-icon size="16">mdi-cart</v-icon>
                </v-btn>
            </v-badge>
        </template>

        <div class="cell">
            <div class="secondary pa-3 text-center">
                <div class="sub-title-2 font-weight-bold white--text">
                    {{ cart_products.length }} {{ $tc('global.name.product', cart_products.length) }}
                </div>
                <div class="mt-1 paragraph white--text">
                    {{ $tc('navbar.Cart.in_cart', 1) }}
                </div>
            </div>

            <v-list class="py-0 cell" dense style="max-height: 500px; overflow-y: auto; overflow-x: hidden">
                <v-list-item v-for="(item,i) in cart_products" :key="i" @click="$router.push('/marketplace/product/' + item.product.id)" link two-line>
                    <v-list-item-content class="pr-3">
                        <v-list-item-content class="sub-title-2 mb-1">{{ item.product.display_name }}</v-list-item-content>
                        <v-list-item-subtitle class="paragraph">
                            <v-row class="mx-0" dense>
                                <v-col cols="auto" class="pl-0">
                                    <div class="primary--text sub-title-2 font-weight-bold" v-html="TotalProductPrice ? $options.filters.formatPrice(TotalProductPrice.find(e => e.id === item.id).price, '€'): ''"></div>
                                </v-col>
                                <v-col>
                                    <div class="paragraph">x{{ item.count }}</div>
                                </v-col>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn small icon @click.stop="removeItem(item, i)">
                            <v-icon size="18">mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <v-divider class="mx-6" v-if="cart_products.length > 0" />
            <v-list-item v-if="cart_products.length > 0">
                <v-list-item-content class="text-center">
                    <div class="sub-title-1 font-weight-bold" style="display: flex; justify-content: space-between">
                        <div>Total : </div>
                        <div class="primary--text" v-html="$options.filters.formatPrice(getTotalCartPrice, '€')"></div>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <div v-if="cart_products.length > 0">
                <v-btn tile block dark color="primary" height="40" to="/marketplace/cart" @click="cart_menu = false">
                    <v-icon left size="16">mdi-cart</v-icon>
                    <div class="paragraph font-weight-bold">{{ $tc('navbar.Cart.cart', 1) }}</div>
                </v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: "Cart",
    data(){
        return {
            cart_menu: false
        }
    },
    computed: {
        cart_products: {
            get(){
                return this.$store.getters['cart/products']
            },
            set(val){
                this.$store.commit('CHANGE_PRODUCTS_VAL', val)
            }
        },
        getTotalCartPrice(){
            let total = 0
            this.TotalProductPrice.forEach(ele => total += ele.count * ele.price)
            return total
        },
        TotalProductPrice(){
            let total = []
            this.cart_products.forEach(product => {
                if (product.extensions) {
                    if(product.extensions.length > 0){
                        let res = 0

                        for(let i = 0; i < product.extensions.length; i++){
                            res += product.extensions[i].extension.price * (product.extensions[i].count ? product.extensions[i].count : 0)
                        }
                        

                        total.push({id: product.id, count: product.count, price: res + product.product.price})
                    }
                    else {
                        total.push({id: product.id, count: product.count, price: product.product.price })
                    }  
                }
                else {
                    total.push({id: product.id, count: product.count, price: product.product.price })
                } 
               
            })
            return total

            
        }
    },
    methods: {
        removeItem(item, index){
            this.$wsc.deleteItem('cart', item.id, success => {
                this.$store.dispatch('cart/removeItem', index)
                this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.removeItem.success'))
            }, fail => {
                this.$store.dispatch('snackbar/error', this.$t('snackbar.Cart.removeItem.fail'))
            })
        },
        getCart(){
            this.$wsc.getList('cart', {}, success => {
                success.forEach(product => {
                    this.$wsc.getList('cart/'+ product.id+ '/extension', {}, exts =>{
                        this.$set(product,'extensions', exts)
                    }, fail => {

                    })
                })
                
                this.$store.commit('cart/CHANGE_PRODUCTS_VAL', success)
            }, 
            fail =>{

            })
        }
    }, 
    created(){
        this.getCart()
    }
}
</script>
